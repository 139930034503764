import { styles } from "../consts";
import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import storesContext from "../../context/stores";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import { Button, HelperText, TextInput } from "react-native-paper";

const ResetPasswordForm: React.FC = observer(() => {
  const { Store } = useContext(storesContext);
  const [username, setUsername] = useState(Store.userEmail);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState("");

  const handleResetPassword = async () => {
    setErrorMessage("");
    try {
      const { isPasswordReset, nextStep } = await resetPassword({ username });
      setStep(nextStep.resetPasswordStep);
    } catch (error) {
      if (error.message.includes("is required")) {
        setErrorMessage("Email is required.");
      } else if (
        error.message.includes("Invalid email address") ||
        error.message.includes("id combination not found")
      ) {
        setErrorMessage("Invalid email address.");
      } else if (error.message.includes("limit exceeded")) {
        setErrorMessage("Reset limit exceeded, please try again later.");
      } else {
        setErrorMessage("Failed to send code.");
      }
    }
  };

  const handleConfirmResetPassword = async () => {
    setErrorMessage("");
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      const data = await confirmResetPassword({
        username,
        newPassword,
        confirmationCode,
      });
      Store.setNextAuthStep("SIGNIN_FORM");
      Store.setUserEmail(username);
    } catch (error) {
      if (error.message.includes("is required")) {
        setErrorMessage("Some fields are missing.");
      } else if (
        error.message.includes("Invalid email address") ||
        error.message.includes("id combination not found")
      ) {
        setErrorMessage("Invalid email address.");
      } else if (error.message.includes("Invalid verification code")) {
        setErrorMessage("Invalid verification code.");
      } else if (error.message.includes("Password does not conform")) {
        setErrorMessage(
          "Password must contain upper case, numeric and special characters."
        );
      } else {
        setErrorMessage("Failed to reset password.");
      }
    }
  };

  return (
    <>
      {step === "" ? (
        <>
          <TextInput
            style={styles.formInput}
            label="Email"
            placeholder="Email"
            value={username}
            onChangeText={setUsername}
            mode="outlined"
            keyboardType="email-address"
            autoCapitalize="none"
          />
          {errorMessage !== "" && (
            <HelperText style={styles.formText} type="error" visible={true}>
              {errorMessage}
            </HelperText>
          )}
          <Button
            style={styles.formButton}
            mode="contained"
            onPress={handleResetPassword}
          >
            Send Code
          </Button>
        </>
      ) : (
        <>
          <TextInput
            style={styles.formInput}
            label="Email"
            placeholder="Email"
            value={username}
            onChangeText={setUsername}
            mode="outlined"
            keyboardType="email-address"
            autoCapitalize="none"
          />
          <TextInput
            style={styles.formInput}
            label="Confirmation Code"
            placeholder="Confirmation Code"
            value={confirmationCode}
            onChangeText={setConfirmationCode}
            mode="outlined"
            keyboardType="numeric"
          />
          <TextInput
            style={styles.formInput}
            label="New Password"
            placeholder="New Password"
            value={newPassword}
            onChangeText={setNewPassword}
            mode="outlined"
            secureTextEntry
          />
          <TextInput
            style={styles.formInput}
            label="Confirm New Password"
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChangeText={setConfirmNewPassword}
            mode="outlined"
            secureTextEntry
          />
          {errorMessage !== "" && (
            <HelperText style={styles.formText} type="error" visible={true}>
              {errorMessage}
            </HelperText>
          )}
          <Button
            style={styles.formButton}
            mode="contained"
            onPress={handleConfirmResetPassword}
          >
            Reset Password
          </Button>
        </>
      )}
      <HelperText
        style={styles.formButton}
        type="info"
        onPress={() => Store.setNextAuthStep("SIGNIN_FORM")}
      >
        Back to Sign In
      </HelperText>
    </>
  );
});

export default ResetPasswordForm;
