import { styles, mobileWidth } from "./consts";
import React, { useState, useEffect } from "react";
import { ScrollView, View, Dimensions, Text } from "react-native";
import { List } from "react-native-paper";
import { generateClient } from "aws-amplify/api";
import { listNotes } from "../graphql/queries";
import {
  onCreateNote,
  onUpdateNote,
  onDeleteNote,
} from "../graphql/subscriptions";
import { Note } from "../API";
import { StackNavigationProp } from "@react-navigation/stack";

const windowWidth = Dimensions.get("window").width;

export type StackParamList = {
  Notes: undefined;
  Note: { note: Note };
};

type MyListNavigationProp = StackNavigationProp<StackParamList, "Notes">;

interface Props {
  navigation: MyListNavigationProp;
}

const Description = ({ description, updatedAt }) => {
  const formattedDate = new Date(updatedAt).toLocaleDateString();

  return (
    <View style={styles.descriptionContainer}>
      <Text style={styles.descriptionText} numberOfLines={2}>
        {description}
      </Text>
      <Text style={styles.updatedAtText}>{formattedDate}</Text>
    </View>
  );
};

const Notes: React.FC<Props> = ({ navigation }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const client = generateClient();

  const sortNotes = (notes: Note[]) => {
    const sortedNotes = notes.sort((a: Note, b: Note) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });
    return sortedNotes;
  };

  useEffect(() => {
    const getNotes = async () => {
      let allNotes = [];
      let nextToken = null;

      // By default list restuls are paginated
      // Keep fetching while nextToken is being returned
      do {
        const response = await client.graphql({
          query: listNotes,
          variables: { nextToken },
        });

        allNotes = allNotes.concat(response.data.listNotes.items);
        nextToken = response.data.listNotes.nextToken;
      } while (nextToken);

      const sortedNotes = sortNotes(allNotes);
      setNotes(sortedNotes);
    };

    getNotes();

    // Update list on create, update and delete events
    const createSub = client.graphql({ query: onCreateNote }).subscribe({
      next: (noteData) => {
        const newNote = noteData.data.onCreateNote;
        navigation.navigate("Note", { note: newNote });
        setNotes((prevNotes) => [newNote, ...prevNotes]);
      },
    });
    const updateSub = client.graphql({ query: onUpdateNote }).subscribe({
      next: (noteData) => {
        const updatedNote = noteData.data.onUpdateNote;
        setNotes((prevNotes) => {
          const filteredNotes = prevNotes.filter(
            (note) => note.id !== updatedNote.id
          );
          return [updatedNote, ...filteredNotes];
        });
      },
    });
    const deleteSub = client.graphql({ query: onDeleteNote }).subscribe({
      next: (noteData) => {
        const deletedNote = noteData.data.onDeleteNote;
        setNotes((prevNotes) =>
          prevNotes.filter((note) => note.id !== deletedNote.id)
        );
      },
    });

    // Unsubscribe from subscriptions when component is unmounted
    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
      deleteSub.unsubscribe();
    };
  }, []);

  return (
    <View style={styles.outerContainer}>
      <View
        style={
          windowWidth > mobileWidth
            ? styles.desktopContainer
            : styles.mobileContainer
        }
      >
        <ScrollView>
          <List.Section>
            {notes.map((note) => (
              <List.Item
                key={note.id}
                title={note.name}
                description={() => (
                  <Description
                    description={note.description}
                    updatedAt={note.updatedAt}
                  />
                )}
                onPress={() => navigation.navigate("Note", { note: note })}
              />
            ))}
          </List.Section>
        </ScrollView>
      </View>
    </View>
  );
};

export default Notes;
