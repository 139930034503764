import { styles, mobileWidth } from "./consts";
import { View, Dimensions, Text, Linking } from "react-native";
import { Chip, PaperProvider } from "react-native-paper";
import React, { useContext } from "react";
import { observer } from "mobx-react";
import storesContext from "../context/stores";
import SignOutForm from "./auth_forms/SignOutForm";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";
import DeleteAccount from "./auth_forms/DeleteAccount";

const Stack = createStackNavigator();
const windowWidth = Dimensions.get("window").width;

const Account: React.FC = observer(() => {
  const { Store } = useContext(storesContext);

  return (
    <View style={styles.outerContainer}>
      <PaperProvider>
        <View
          style={
            windowWidth > mobileWidth
              ? styles.desktopContainer
              : styles.mobileContainer
          }
        >
          <View style={{ margin: 10, marginTop: "5%" }}>
            <Chip>Username: {Store.userEmail}</Chip>
            <SignOutForm />
          </View>
          <Text style={{ margin: 20, marginTop: 40, textAlign: "center" }}>
            Access your notes through the website:
          </Text>
          <Text
            style={{ textAlign: "center", textDecorationLine: "underline" }}
            onPress={() => Linking.openURL("https://simplenoter.com")}
          >
            https://simplenoter.com
          </Text>
        </View>
        <View
          style={
            windowWidth > mobileWidth
              ? styles.desktopFooterContainer
              : styles.mobileFooterContainer
          }
        >
          <View style={{ margin: 10, marginBottom: "5%" }}>
            <DeleteAccount />
          </View>
        </View>
      </PaperProvider>
    </View>
  );
});

const AccountPage: React.FC = () => {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Account">
        <Stack.Screen name="Account" component={Account} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default AccountPage;
