import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import storesContext from "./context/stores";
import { BottomNavigation, Text } from "react-native-paper";
import amplifyconfig from "./amplifyconfiguration.json";
import { getCurrentUser } from "aws-amplify/auth";
import { styles } from "./components/consts";
import { Amplify } from "aws-amplify";
import { SafeAreaProvider } from "react-native-safe-area-context";
import AccountPage from "./components/AccountPage";
import NotesPage from "./components/NotesPage";
import SignInPage from "./SignInPage";
import { Hub } from "aws-amplify/utils";
import { StatusBar, Appearance, View, Linking } from "react-native";
import { Platform } from "react-native";

Amplify.configure(amplifyconfig);

const NoterApp: React.FC = observer(() => {
  const { Store } = useContext(storesContext);
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    {
      key: "notes",
      title: "Notes",
      focusedIcon: "note",
    },
    { key: "accounts", title: "Account", focusedIcon: "account" },
  ]);

  const colorScheme = Appearance.getColorScheme(); // 'light' or 'dark'

  const currentAuthenticatedUser = async () => {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      Store.setIsSignedIn(true);
      Store.setUserEmail(signInDetails.loginId);
    } catch (err) {
      Store.setIsSignedIn(false);
      Store.setUserEmail("");
    }
  };

  const renderScene = BottomNavigation.SceneMap({
    notes: NotesPage,
    accounts: AccountPage,
  });

  useEffect(() => {
    // Check for logged in user on page refresh
    currentAuthenticatedUser();
    // Setup listener to check on any future auth events
    const hubListenerCancelToken = Hub.listen("auth", currentAuthenticatedUser);
    return () => {
      hubListenerCancelToken();
    };
  }, []);

  return (
    <>
      {colorScheme === "light" ? (
        <StatusBar barStyle="dark-content" />
      ) : (
        <StatusBar barStyle="light-content" backgroundColor="#333" />
      )}
      {Store.isSignedIn === true ? (
        <SafeAreaProvider>
          <BottomNavigation
            navigationState={{ index, routes }}
            onIndexChange={setIndex}
            renderScene={renderScene}
            style={{ borderTopColor: "black" }}
          />
        </SafeAreaProvider>
      ) : (
        <SignInPage />
      )}
    </>
  );
});

/**
 * For the web app, render delete account guide if the delete subdomain is accessed.
 */

const DeleteAccountGuide: React.FC = () => {
  return (
    <View style={styles.outerContainer}>
      <View style={styles.desktopContainer}>
        <Text style={{ marginTop: 50, fontSize: 20 }}>
          Deleting your Noter account:
        </Text>
        <Text style={{ marginTop: 20 }}>
          To delete your account and all associated user data, login to the
          Noter website via the below link, navigate to your account page and
          click Delete Account at the bottom of the page.
          <br />
          <br />
          If you have forgotten your login credentials then you will first need
          to click the 'Forgot Password' link on the login page and follow the
          prompts to recover your account.
        </Text>
        <Text style={{ marginTop: 30, alignSelf: "center" }}>
          <a href="https://simplenoter.com">Web Login</a>
        </Text>
      </View>
    </View>
  );
};

const NoterWebApp: React.FC = () => {
  const [isDeleteURL, setIsDeleteURL] = useState<boolean>(false);

  useEffect(() => {
    const handleDeepLink = ({ url }) => {
      const domain = url.split("//")[1];
      const subdomain = domain.split(".")[0];
      console.log(subdomain);
      if (subdomain === "delete-account") {
        setIsDeleteURL(true);
      } else {
        setIsDeleteURL(false);
      }
    };
    // Handle URL on app open
    Linking.getInitialURL()
      .then((url) => {
        if (url) {
          handleDeepLink({ url });
        }
      })
      .catch((err) => {
        console.warn("An error occurred", err);
      });
    // Listen for changes
    Linking.addEventListener("url", handleDeepLink);
  }, []);

  if (isDeleteURL) {
    return <DeleteAccountGuide />;
  } else {
    return <NoterApp />;
  }
};

const Noter: React.FC = () => {
  return Platform.OS === "web" ? <NoterWebApp /> : <NoterApp />;
};

export default Noter;
