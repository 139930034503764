import { styles } from "../consts";
import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import storesContext from "../../context/stores";
import { TextInput, Button, HelperText } from "react-native-paper";
import { signUp } from "aws-amplify/auth";


const SignUpForm: React.FC = observer(() => {
  const { Store } = useContext(storesContext);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      const email = username;
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email,
            name,
          },
          autoSignIn: true, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        },
      });
      Store.setUserEmail(email);
      Store.setNextAuthStep(nextStep.signUpStep);
    } catch (error) {
      if (error.message.includes("username is required")) {
        setErrorMessage("Email is required.");
      } else if (error.message.includes("password is required")) {
        setErrorMessage("Password is required.");
      } else if (error.message.includes("given email already exists")) {
        setErrorMessage("A user with this email already exists.");
      } else if (error.message.includes("Invalid email address")) {
        setErrorMessage("Invalid email address.");
      } else if (
        error.message.includes("Password did not conform with policy")
      ) {
        setErrorMessage(
          "Password must contain upper case, numeric and special characters."
        );
      } else {
        setErrorMessage("Sign up failed.");
      }
    }
  };

  return (
    <>
      <TextInput
        style={styles.formInput}
        label="Email"
        placeholder="Email"
        value={username}
        onChangeText={(val: string) => {
          setUsername(val);
          setName(val);
        }}
        mode="outlined"
        keyboardType="email-address"
        autoCapitalize="none"
      />
      <TextInput
        style={styles.formInput}
        label="Password"
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        mode="outlined"
        secureTextEntry
      />
      <TextInput
        label="Confirm Password"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChangeText={setConfirmPassword}
        mode="outlined"
        secureTextEntry
        style={styles.formInput}
      />
      {errorMessage !== "" && (
        <HelperText style={styles.formText} type="error" visible={true}>
          {errorMessage}
        </HelperText>
      )}
      <Button style={styles.formButton} mode="contained" onPress={handleSignUp}>
        Sign Up
      </Button>
      <HelperText
        style={styles.formButton}
        type="info"
        onPress={() => Store.setNextAuthStep("SIGNIN_FORM")}
      >
        Already have an account? Sign In
      </HelperText>
    </>
  );
});

export default SignUpForm;
