import React from "react";
import { IconButton } from "react-native-paper";
import { generateClient } from "aws-amplify/api";
import { createNote } from "../graphql/mutations";

const CreateNoteButton: React.FC = () => {
  const client = generateClient();

  const createNoteHandler = async () => {
    const result = await client.graphql({
      query: createNote,
      variables: {
        input: {
          name: "Untitled",
        },
      },
    });
  };

  return <IconButton icon="plus" size={25} onPress={createNoteHandler} />;
};

export default CreateNoteButton;
