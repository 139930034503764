import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";
import Note from "./Note";
import Notes from "./Notes";
import { observer } from "mobx-react";
import storesContext from "../context/stores";
import Entypo from "react-native-vector-icons/Entypo";
import CreateNoteButton from "./CreateNoteButton";

const Stack = createStackNavigator();

const CustomMenuButton: React.FC<{ onPress: () => void }> = ({ onPress }) => {
  return (
    <Entypo
      name="dots-three-vertical"
      size={20}
      style={{ marginRight: 15 }}
      onPress={onPress}
    />
  );
};

const NotesPage: React.FC = observer(() => {
  const { Store } = React.useContext(storesContext);

  const toggleMenu = () => Store.setIsNoteMenuVisible(!Store.isNoteMenuVisible);

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="MyList">
        <Stack.Screen
          name="Notes"
          component={Notes}
          options={{
            headerRight: () => <CreateNoteButton />,
          }}
        />
        <Stack.Screen
          name="Note"
          component={Note}
          options={{
            headerRight: () => <CustomMenuButton onPress={toggleMenu} />,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
});

export default NotesPage;
