import React, { useContext } from "react";
import { observer } from "mobx-react";
import storesContext from "./context/stores";
import { styles, mobileWidth } from "./components/consts";
import SignInForm from "./components/auth_forms/SignInForm";
import SignUpForm from "./components/auth_forms/SignUpForm";
import SignUpConfirmForm from "./components/auth_forms/SignUpConfirmForm";
import { View, Dimensions } from "react-native";
import ResetPasswordForm from "./components/auth_forms/ResetPasswordForm";

const windowWidth = Dimensions.get("window").width;

const SignInPage: React.FC = observer(() => {
  const { Store } = useContext(storesContext);

  return (
    <View style={styles.outerContainer}>
      <View
        style={
          windowWidth > mobileWidth
            ? styles.desktopContainer
            : styles.mobileContainer
        }
      >
        <View style={styles.topMargin}>
          {Store.nextAuthStep === "SIGNUP_FORM" ? (
            <SignUpForm />
          ) : Store.nextAuthStep === "CONFIRM_SIGN_UP" ? (
            <SignUpConfirmForm />
          ) : Store.nextAuthStep === "RESET_PASSWORD" ? (
            <ResetPasswordForm />
          ) : (
            <SignInForm />
          )}
        </View>
      </View>
    </View>
  );
});

export default SignInPage;
