import { styles } from "../consts";
import React, { useState } from "react";
import { generateClient } from "aws-amplify/api";
import {
  Button,
  Dialog,
  HelperText,
  Portal,
  Text,
  TextInput,
} from "react-native-paper";
import { listNotes } from "../../graphql/queries";
import { deleteNote } from "../../graphql/mutations";
import { deleteUser } from "@aws-amplify/auth";

const SignOutForm: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] =
    useState<boolean>(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] =
    useState<boolean>(true);
  const client = generateClient();

  const deleteAccountHandler = async () => {
    try {
      // Fetch and delete notes for user
      const results = await client.graphql({ query: listNotes });
      const notes = results.data.listNotes.items;
      notes.map(async (note) => {
        await client.graphql({
          query: deleteNote,
          variables: { input: { id: note.id } },
        });
      });

      // delete authenticated user
      const data = await deleteUser();
    } catch {
      setErrorMessage("Failed to complete account deletion.");
    }
  };

  return (
    <>
      {errorMessage !== "" && (
        <HelperText style={styles.formText} type="error" visible={true}>
          {errorMessage}
        </HelperText>
      )}
      <Button
        style={{ marginTop: 10 }}
        onPress={() => setIsDeleteDialogVisible(true)}
        textColor="black"
        icon="delete"
      >
        Delete Account
      </Button>

      <Portal>
        <Dialog
          visible={isDeleteDialogVisible}
          onDismiss={() => setIsDeleteDialogVisible(false)}
        >
          <Dialog.Title>Confirm Account Deletion</Dialog.Title>
          <Dialog.Content>
            <Text style={{ marginBottom: 15 }}>
              Are you sure you want to delete your account? Please be aware that
              this action will cause all of your notes to be permanently
              deleted.
            </Text>
            <Text style={{ marginBottom: 15 }}>
              If you wish to proceed, please type{" "}
              <Text style={{ fontStyle: "italic" }}>delete</Text> below and then
              click Delete Account.
            </Text>
            <TextInput
              style={styles.formInput}
              placeholder="delete"
              onChangeText={(value) => {
                if (value === "delete") {
                  setIsDeleteButtonDisabled(false);
                } else {
                  setIsDeleteButtonDisabled(true);
                }
              }}
              mode="outlined"
              autoCapitalize="none"
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setIsDeleteDialogVisible(false)}>
              Cancel
            </Button>
            <Button
              onPress={deleteAccountHandler}
              disabled={isDeleteButtonDisabled}
              mode="contained"
            >
              Delete Account
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
};

export default SignOutForm;
