import { styles } from "../consts";
import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import storesContext from "../../context/stores";
import { Button, HelperText } from "react-native-paper";
import { signOut } from "aws-amplify/auth";

const SignOutForm: React.FC = observer(() => {
  const { Store } = useContext(storesContext);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSignOut = async () => {
    try {
      await signOut();
      Store.setNextAuthStep("SIGNIN_FORM");
      Store.setIsSignedIn(false);
    } catch (error) {
      setErrorMessage("Sign out failed.");
    }
  };

  return (
    <>
      {errorMessage !== "" && (
        <HelperText style={styles.formText} type="error" visible={true}>
          {errorMessage}
        </HelperText>
      )}
      <Button
        style={styles.formButton}
        mode="outlined"
        onPress={handleSignOut}
        icon="logout"
      >
        Sign Out
      </Button>
    </>
  );
});

export default SignOutForm;
