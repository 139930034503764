import { styles } from "../consts";
import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import storesContext from "../../context/stores";
import { TextInput, Button, HelperText } from "react-native-paper";
import { confirmSignUp, resendSignUpCode } from "aws-amplify/auth";

const SignUpConfirmForm: React.FC = observer(() => {
  const { Store } = useContext(storesContext);
  const [username, setUsername] = useState<string>(Store.userEmail);
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleSignUpConfirmation = async () => {
    setSuccessMessage("");
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode,
      });
      Store.setUserEmail(username);
      Store.setNextAuthStep(nextStep.signUpStep);
    } catch (error) {
      if (error.message.includes("username is required")) {
        setErrorMessage("Email is required.");
      } else if (
        error.message.includes("Username/client id combination not found")
      ) {
        setErrorMessage("No user found with this email.");
      } else if (
        error.message.includes("code is required") ||
        error.message.includes("Invalid verification code")
      ) {
        setErrorMessage("Invalid verification code.");
      } else {
        setErrorMessage("Sign up confirmation failed.");
      }
    }
  };

  const handleResendCode = async () => {
    try {
      await resendSignUpCode({ username });
      setErrorMessage("");
      setSuccessMessage("Code resent. Please check your email.");
    } catch (error) {
      if (error.message.includes("username is required")) {
        setErrorMessage("Email is required.");
      } else if (
        error.message.includes("Username/client id combination not found")
      ) {
        setErrorMessage("No user found with this email.");
      } else {
        setErrorMessage("Failed to resend code.");
      }
    }
  };

  return (
    <>
      <TextInput
        style={styles.formInput}
        label="Email"
        placeholder="Email"
        value={username}
        onChangeText={setUsername}
        mode="outlined"
        keyboardType="email-address"
        autoCapitalize="none"
      />
      <TextInput
        style={styles.formInput}
        label="Confirmation Code"
        placeholder="Code"
        value={confirmationCode}
        onChangeText={setConfirmationCode}
        mode="outlined"
      />
      {successMessage !== "" && (
        <HelperText style={styles.formText} type="info" visible={true}>
          {successMessage}
        </HelperText>
      )}
      {errorMessage !== "" && (
        <HelperText style={styles.formText} type="error" visible={true}>
          {errorMessage}
        </HelperText>
      )}
      <Button
        style={styles.formButton}
        mode="contained"
        onPress={handleSignUpConfirmation}
      >
        Confirm Email
      </Button>
      <Button style={styles.formButton} mode="text" onPress={handleResendCode}>
        Resend Code
      </Button>
      <HelperText
        style={styles.formButton}
        type="info"
        onPress={() => Store.setNextAuthStep("SIGNIN_FORM")}
      >
        Login
      </HelperText>
    </>
  );
});

export default SignUpConfirmForm;
