import amplifyconfig from "./src/amplifyconfiguration.json";
import { Amplify } from "aws-amplify";
import { Provider } from "mobx-react";
import storesContext from "./src/context/stores";
import Noter from "./src/Noter";

Amplify.configure(amplifyconfig);

export default function App() {
  return (
    <Provider {...storesContext}>
      <Noter />
    </Provider>
  );
}
