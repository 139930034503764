import { StyleSheet } from "react-native";

export const mobileWidth = 768;

export const styles = StyleSheet.create({
  topMargin: {
    marginTop: "30%",
    margin: 10,
  },
  outerContainer: {
    flex: 1,
  },
  mobileContainer: {
    flex: 1,
  },
  desktopContainer: {
    width: "40%",
    alignSelf: "center",
    flex: 1,
  },
  formInput: {
    marginTop: 5,
  },
  mobileFooterContainer: {},
  desktopFooterContainer: {
    width: "40%",
    alignSelf: "center",
  },
  formButton: {
    textAlign: "center",
    marginTop: 20,
  },
  formText: {
    textAlign: "center",
    marginTop: 10,
  },
  noteTitle: {
    flex: 1,
    fontSize: 18,
    backgroundColor: "transparent",
  },
  scrollView: {
    flexGrow: 1,
  },
  noteText: {
    flexGrow: 1,
    fontSize: 16,
    backgroundColor: "transparent",
    marginTop: 0,
  },
  desktopNoteText: {
    flexGrow: 1,
    fontSize: 16,
    backgroundColor: "transparent",
    padding: 15,
  },
  noteButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 10,
  },
  descriptionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start", // Align items to the top
  },
  descriptionText: {
    flex: 1,
  },
  updatedAtText: {
    marginLeft: 10,
    color: "gray",
    alignSelf: "flex-end", // Align the date to the bottom
  },
});
