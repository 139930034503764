import React, { useEffect, useState, useContext } from "react";
import {
  TextInput,
  Button,
  Dialog,
  Portal,
  PaperProvider,
  Menu,
} from "react-native-paper";
import { styles, mobileWidth } from "./consts";
import { updateNote, deleteNote } from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { UpdateNoteInput } from "../API";
import { RouteProp } from "@react-navigation/native";
import { StackParamList } from "./Notes";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  View,
  Dimensions,
  Text,
  TextInput as NativeTextInput,
} from "react-native";
import { observer } from "mobx-react";
import { debounce } from "lodash";
import storesContext from "../context/stores";
import { Platform } from "react-native";

const windowWidth = Dimensions.get("window").width;

type DetailsRouteProp = RouteProp<StackParamList, "Note">;
type MyListNavigationProp = StackNavigationProp<StackParamList, "Notes">;

interface Props {
  route: DetailsRouteProp;
  navigation: MyListNavigationProp;
}
const Note: React.FC<Props> = observer(({ route, navigation }) => {
  const { Store } = useContext(storesContext);
  const { note } = route.params;
  const [updateNoteInput, setUpdateNoteInput] = useState<UpdateNoteInput>({
    id: note.id,
    name: note.name,
    description: note.description,
  });
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] =
    useState<boolean>(false);
  const [isNoteUpdated, setIsNoteUpdated] = useState<boolean>(false);
  const client = generateClient();

  const updateNoteHandler = async () => {
    await client.graphql({
      query: updateNote,
      variables: {
        input: updateNoteInput,
      },
    });
    Store.setIsNoteMenuVisible(false);
  };

  const debouncedUpdateNoteHandler = debounce(updateNoteHandler, 1000);

  useEffect(() => {
    if (isNoteUpdated) {
      debouncedUpdateNoteHandler();
    }
    return () => {
      debouncedUpdateNoteHandler.cancel();
    };
  }, [updateNoteInput]);

  const deleteNoteHandler = async () => {
    await client.graphql({
      query: deleteNote,
      variables: { input: { id: note.id } },
    });
    Store.setIsNoteMenuVisible(false);
    navigation.navigate("Notes");
  };

  return (
    <PaperProvider>
      <View style={styles.outerContainer}>
        <View
          style={
            windowWidth > mobileWidth
              ? styles.desktopContainer
              : styles.mobileContainer
          }
        >
          <View style={styles.noteButtonContainer}>
            <TextInput
              textColor="#000"
              style={styles.noteTitle}
              value={updateNoteInput.name}
              onChangeText={(value) => {
                setIsNoteUpdated(true);
                setUpdateNoteInput({ ...updateNoteInput, name: value });
              }}
            />
          </View>
          {Platform.OS === "web" ? (
            <NativeTextInput
              style={styles.desktopNoteText}
              multiline
              placeholder="Start typing your notes here..."
              placeholderTextColor="#888"
              value={updateNoteInput.description}
              onChangeText={(value) => {
                setIsNoteUpdated(true);
                setUpdateNoteInput({
                  ...updateNoteInput,
                  description: value,
                });
              }}
              textAlignVertical="top"
            />
          ) : (
            <View style={{ flex: 1 }}>
              <TextInput
                textColor="#000"
                style={styles.noteText}
                multiline
                placeholder="Start typing your notes here..."
                placeholderTextColor="#888"
                value={updateNoteInput.description}
                onChangeText={(value) => {
                  setIsNoteUpdated(true);
                  setUpdateNoteInput({
                    ...updateNoteInput,
                    description: value,
                  });
                }}
                textAlignVertical="top"
              />
            </View>
          )}
          <Menu
            visible={Store.isNoteMenuVisible}
            onDismiss={() => Store.setIsNoteMenuVisible(false)}
            anchor={{ x: windowWidth, y: 0 }}
          >
            <Menu.Item
              onPress={() => {
                setIsDeleteDialogVisible(true);
                Store.setIsNoteMenuVisible(false);
              }}
              title="Delete"
            />
          </Menu>
          <Portal>
            <Dialog
              visible={isDeleteDialogVisible}
              onDismiss={() => setIsDeleteDialogVisible(false)}
            >
              <Dialog.Title>Confirm Delete</Dialog.Title>
              <Dialog.Content>
                <Text>Are you sure you want to delete this note?</Text>
              </Dialog.Content>
              <Dialog.Actions>
                <Button onPress={() => setIsDeleteDialogVisible(false)}>
                  Cancel
                </Button>
                <Button onPress={deleteNoteHandler}>Delete</Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </View>
      </View>
    </PaperProvider>
  );
});

export default Note;
