import { makeAutoObservable } from "mobx"

class Store {
    isSignedIn = false
    isNoteMenuVisible = false
    nextAuthStep = ''
    userEmail = ''

    constructor() {
        makeAutoObservable(this)
    }

    setIsSignedIn(isSignedIn: boolean) {
        this.isSignedIn = isSignedIn;
    }

    setIsNoteMenuVisible(isNoteMenuVisible: boolean) {
        this.isNoteMenuVisible = isNoteMenuVisible
    }

    setNextAuthStep(nextAuthStep: string) {
        this.nextAuthStep = nextAuthStep;
    }

    setUserEmail(userEmail: string) {
        this.userEmail = userEmail;
    }
}

export default new Store()
