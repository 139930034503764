import { styles } from "../consts";
import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import storesContext from "../../context/stores";
import { TextInput, Button, HelperText } from "react-native-paper";
import { signIn } from "aws-amplify/auth";

const SignInForm: React.FC = observer(() => {
  const { Store } = useContext(storesContext);
  const [username, setUsername] = useState<string>(Store.userEmail);
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSignIn = async () => {
    Store.setUserEmail(username);
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      Store.setUserEmail(username);
      Store.setNextAuthStep(nextStep.signInStep);
      Store.setIsSignedIn(isSignedIn);
    } catch (error) {
      if (error.message.includes("already a signed in user")) {
        Store.setIsSignedIn(true);
      } else if (
        error.message.includes("Incorrect username or password") ||
        error.message.includes("password is required to signIn") ||
        error.message.includes("username is required to signIn") ||
        error.message.includes("User does not exist")
      ) {
        setErrorMessage("Incorrect username or password");
      } else {
        setErrorMessage("Sign in failed.");
      }
    }
  };

  return (
    <>
      <TextInput
        style={styles.formInput}
        label="Email"
        placeholder="Email"
        value={username}
        onChangeText={setUsername}
        mode="outlined"
        keyboardType="email-address"
        autoCapitalize="none"
      />
      <TextInput
        style={styles.formInput}
        label="Password"
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        mode="outlined"
        secureTextEntry
      />
      {errorMessage !== "" && (
        <HelperText style={styles.formText} type="error" visible={true}>
          {errorMessage}
        </HelperText>
      )}
      <Button style={styles.formButton} mode="contained" onPress={handleSignIn}>
        Sign In
      </Button>
      <HelperText
        style={styles.formButton}
        type="info"
        onPress={() => Store.setNextAuthStep("SIGNUP_FORM")}
      >
        Don't have an account? Sign Up
      </HelperText>
      <HelperText
        style={styles.formButton}
        type="info"
        onPress={() => Store.setNextAuthStep("RESET_PASSWORD")}
      >
        Forgot Password? Reset
      </HelperText>
    </>
  );
});

export default SignInForm;
